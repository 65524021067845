.mapHistory {
    max-width: 68.75rem !important;
    height: 80vh !important;
    margin: 2rem auto 0 auto;
}

.popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}