.container_days {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0.5rem;

}

.container_days .day_button {
    flex: 0 0 40%;
    margin: 0.5rem;
}

.subtitle_notifications {
    font-weight: 500 !important;
    font-size: 1rem;
}

.container_time {
    display: flex;
    align-items: center;
    margin: 1.4rem auto;
    margin-left: 0.5rem;
}

.container_time input {
    margin-left: 1rem;
    text-align: center;
    height: 2rem;
    margin-left: auto;
    margin-right: 0.5rem;
}

.line_bottom {
    margin-bottom: 1.5rem;
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {

    .subtitle_notifications {
        margin-top: 1rem;
    }

    .container_days {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 1.875rem;
        gap: 1.5rem;
    }

    .container_days .day_button {
        flex: 0 0 30%;
        margin: 0 0.1rem;
    }

    .container-time input {
        width: 5.5rem;
        margin-top: 0.015rem;
    }

    .line_bottom {
        margin-bottom: 2rem;
    }

}

@media (min-width: 992px) {

    .container_days .day_button {
        flex: 0 20%;
        margin: 0 0.1rem;
    }

    .line_bottom {
        margin-bottom: 2.5rem;
    }
}