.containerAccordion {
    margin-top: 1rem;
}

.containerAccordion .iconUnlicensed {
    font-size: 1.5rem;
    background-color: var(--dark-color);
    color: var(--light-color);
    border-radius: 100%;
    margin-right: 0.5rem;
}

.containerAccordion .accordion_item{
    border: none !important;
}

.containerAccordion .accordionHeader button.accordion-button{
    background-color: var(--gray-light-color) !important;
}

.containerAccordion .accordionBody{
    background-color: var(--gray-light-color) !important;
}

.accordion_header > button {
    box-shadow: none !important;
    background-color: var(--gray-light-color) !important;
}