.containerToggle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.btnDark {
    padding: 0.375rem 0.75rem;
    background-color: var(--dark-color);
}

.containerToggle Button:hover {
    background-color: var(--gray-color);
}

/* version Tablet 689px hasta 991px */
@media (max-width: 689px) {
    .containerToggle {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .containerToggle Button {
        width: 100%;
        margin-bottom: 1rem;
        margin-left: 0;
        margin-left: 0 !important;
    }
}