.label_block {
  font-size: 1.2rem;
  margin: 0.7rem 0 0.7rem 0.2rem;
  font-weight: 500 !important;
}

.container_map .label_block {
  display: block;
}

.container_map input,
textarea {
  border: solid 0.06rem;
  outline: none;
  padding: 0.365rem 0.75rem;
  font-size: 1rem;
  color: var(--grayish-blue);
  background-color: var(--light-color);
  background-clip: padding-box;
  border: 0.063rem solid var(--gray-intermediate-color);
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  resize: none;
}

.container_map .input_width {
  width: 100%;
  text-overflow: ellipsis;
}

.container_map .container_inputs {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.container_color {
  display: flex;
  align-items: center;
}

.container_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_map input[type="color"] {
  border: none;
  width: 3.3rem;
  height: 2.5rem;
}

.container_map input[type="number"] {
  padding: 0 0.5rem;
  margin: 0;
  width: 3.5rem;
  border: none;
}

.container_map input[type="number"]::-webkit-inner-spin-button,
.container_map input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container_map .container_inputs .container_range {
  border: 0.063rem solid var(--gray-intermediate-color);
  border-radius: 0.25rem;
}

.input_range {
  width: 80%;
  margin-right: 1rem;
}

.container_map select {
  padding: 0.365rem 0rem;
  color: var(--grayish-blue);
  background-clip: padding-box;
  border: none;
}

.errorMessage {
  color: var(--red-color);
  font-size: 0.8rem;
  margin: 0.5rem 0 0;
  display: none;
}

.visible {
  display: block;
}

input.error {
  box-shadow: 0 0 0.3125rem var(--red-color) !important;
}

.places_list {
  list-style: none;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-left: 0;
  box-shadow: 0 0 0.3125rem var(--gray-intermediate-color) !important;
}

.places_list li {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  padding: 0.5rem;
  margin: 0;
}

.places_list li:hover {
  cursor: pointer;
  background-color: var(--light-intermediate-color);
}


/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {
  .container_map {
    margin: 0 auto;
    width: 95%;
  }
}


/* version Full-Desktop 992px hasta 1311px */
@media (min-width: 1312px) {}