.arrow {
    display: flex;
    justify-content: center;
    align-items: center !important;
    text-decoration: none;
    background-color: var(--dark-color) !important;
    margin-bottom: 0;
}

.arrow:hover {
    background-color: var(--gray-shadow-color) !important;
}

@media (max-width: 689px) {
    .arrow {
        width: 100%;
        margin-bottom: 1rem;
    }
}