.body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 1.5rem;
    border-radius: 1.5625rem;
}

.body p {
    margin: 0 0 0 0.4375rem;
    font-weight: 500;
}