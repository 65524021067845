.headerApps .buttonExtract .button {
    background-color: var(--dark-color) !important;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
}

.headerApps .buttonExtract .button:hover {
    background-color: var(--gray-shadow-color) !important;
}

.filterContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.dateFilterContainer .btnDateUseTimeApps:nth-child(2n) {
    margin: 0 0.1rem !important;
}

.dateFilterContainer .btnDateUseTimeApps {
    background-color: var(--gray-light-btn-menu);
    border-radius: 0;
    color: var(--dark-color);
    border: none;
    width: 5rem;
    font-size: 0.9rem;
    font-weight: 500;
    height: 2.5rem;
    margin-bottom: 0;
    padding: 0.5rem;
}

.btnsContainer .btnDateHistoric {
    border-radius: 0;
    height: 2.5rem;
    margin-bottom: 0;
    background-color: #0148d3;
    border: none;
    font-size: 1.1rem;
}

.btnsContainer .btnDateHistoric:hover {
    background-color: var(--dark-color) !important;
}

.dateFilterContainer .btnDateUseTimeApps:hover

/* , .btnsContainer .btnDateUseTimeApps:active */
    {
    background-color: var(--dark-color) !important;
    color: var(--light-color) !important;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltipText {
    visibility: hidden;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 0.3125rem;
    border-radius: 0.375rem;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -50px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: .9rem;
}

.tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.filterByCategories {
    margin-bottom: 1rem;
}

.filterByCategories .floatLabel {
    color: var(--gray-color);
    margin: 0;
    font-weight: 400;
    font-size: 0.9rem;
    padding-left: 0.5rem;
}

.filterByCategories .dropdown {
    height: 2.5rem;
    width: 100%;
}

.horizontalBarContainer {
    margin-bottom: 2rem;
    position: relative;
}

.expandIcon {
    padding: 0.6rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    /* background-color: var(--gray-light-btn-menu); */
    color: var(--dark-color);
    box-shadow: 0 0.25rem 0.625rem 0 var(--light-shadow-color);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.expandIcon:hover,
.expandIcon:active {
    background-color: var(--gray-light-btn-menu);
}

.horizontalBarContainer i.visible {
    display: block;
}

.horizontalBarContainer i.notVisible {
    display: none;
}

.buttonExtract {
    display: flex;
    height: 2.5rem;
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
}

.searchBar .search,
.buttonExtract .search {
    cursor: pointer;
    width: 2.5rem !important;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 0.3125rem;
    background-color: var(--dark-color) !important;
    color: var(--light-color);
    border: none !important;
    margin: 0 0 0 0.3rem;
}

.maximize {
    height: 45rem !important;
    transition: height 0.25s ease-in;
}

.minimize {
    height: 27rem !important;
    transition: height 0.25s ease-in;
}

.headerApps .searchBar {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.headerApps .searchBar .containerSearchBar .inputSearch {
    width: 100%;
}

.headerApps .searchBar .containerSearchBar {
    display: flex;
    width: 100%;
}

.headerApps .searchBar .selectFilters {
    width: 100%;
}

@media screen and (min-width: 689px) {

    .headerApps .searchBar .containerSearchBar .inputSearch {
        width: 62%;
    }

    .headerApps .searchBar {
        flex-direction: row;
    }

    .headerApps .searchBar .selectFilters {
        position: absolute;
        right: 0;
        width: 10rem;
    }

    .filterByCategories .dropdown {
        width: 13rem;
    }

    .filterByCategories {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .filterContainer {
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 1300px) {
    .headerApps .searchBar .containerSearchBar .inputSearch {
        width: 80%;
    }
}