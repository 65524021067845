.btns {
    flex-direction: row;
}

.btns Button {
    background-color: var(--dark-color);
    margin-left: 0.625rem;
}

.btns Button:hover {
    background-color: var(--gray-color);
}


@media (max-width: 689px) {
    .btns {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .btns Button {
        width: 100%;
        margin-bottom: 1rem;
        margin-left: 0;
    }
}