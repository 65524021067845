.end-footer-body {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0rem;
}

.sectionBody {
  height: 100% !important;
}

/* .containerHeight {
  padding: 2rem 2rem;
  height: 100% !important;
  background-color: var(--light-color);
  min-height: 100vh;
} */

.container-toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.containerIndex {
  min-height: 100vh;
  margin-left: 0 !important;
}

#resolution {
  width: 17rem;
}

.divMaps {
  /* max-width: 68.75rem !important; */
  height: 26rem !important;
  margin: 0;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.row {
  width: 100%;
  margin: 0 !important;
}

.content-wrapper {
  min-height: 100vh !important;
  background-color: var(--light-color);
}

.contentIndex {
  width: 100%;
}

.size-icon {
  font-size: x-large !important;
}

.zoom:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.trash {
  margin-left: 0.8rem;
}

.carrousel img {
  height: 35rem;
}

.marginCharts {
  border: 0.0625rem solid var(--gray-color);
  padding: 1.5rem;
  margin: 1rem;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.p-toast-bottom-right {
  z-index: 9999;
}

.p-chart {
  width: 100% !important;
  height: 100%;
}

.p-picklist-source-controls {
  display: none !important;
}

.p-picklist-target-controls {
  display: none !important;
}

.p-button {
  background: var(--dark-color) !important;
  border: 0.0625rem solid var(--dark-color) !important;
}

.p-picklist-transfer-buttons :nth-child(4n) {
  display: none !important;
}

.p-picklist-transfer-buttons :nth-child(2n) {
  display: none !important;
}

.p-listbox-empty-message {
  display: flex !important;
  justify-content: center !important;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark-shadow-color) !important;
}

.navbar-light button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input.error {
  box-shadow: 0 0 0.3125rem var(--red-color) !important;
}

select.error {
  box-shadow: 0 0 0.3125rem var(--red-color) !important;
}

select.error:focus {
  border: 0.0625rem solid var(--red-color) !important;
}

.leaflet-container {
  height: 25vh;
}

.cluster-icon {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: var(--light-color);
  transform: translate(-25%, -25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 90;
  font-size: 1.2rem;
}
.p-tabmenu-nav {
  border: none !important;
}
.p-menuitem-text{
  color: var(--blue-shadow-color);
}
.p-tabmenuitem{
  width: 25%;
}
.p-tabmenu-nav a{
  justify-content: center;
  padding: 1rem !important;
  font-size: 0.7rem;
}
.p-tabmenuitem.p-highlight .p-menuitem-link{
  border-color: var(--blue-button-color) !important;
}
.p-menuitem-link:not(.p-disabled):focus{
  box-shadow: none !important;
}

.p-paginator.p-component.p-paginator-bottom button {
  min-width: 2rem;
}

.p-inputtext{
  border-bottom: none !important;
}

.p-slider .p-slider-range{
 background-color: var( --secondary-color) !important;
}

.p-slider .p-slider-handle {
  border-color: var( --secondary-color) !important;
}
/* SWEETALERT */
.swal2-popup {
  font-size: 0.75rem !important;
}

.p-tree-toggler-icon, .p-treenode-icon, .p-treenode-label{
  color: var(--dark-color) !important;
}

/* .p-treenode-selectable.p-highlight{
  background-color: ;
  color: ;
} */
.p-tree{
  width: 100%;
  height: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 500px) {
  .p-tabmenu-nav a{
    font-size: 1rem;
  }
}
/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {
  /* .containerHeight {
    padding: 2rem 3rem;
  } */

  .leaflet-container {
    height: 30vh;
  }

  .p-paginator.p-component.p-paginator-bottom button {
    min-width: 3rem;
}
}

@media (max-width: 689px) {
  .p-toast {
    width: 18rem !important;
  }
}

@media (min-width: 992px) {
  .p-tree{
    width: 20rem;
  }
}

/* version Full-Desktop 992px hasta 1311px */
@media (min-width: 1312px) {
  .p-chart {
    width: 100% !important;
    height: 90% !important;
  }

  .modal-content {
    margin: 0 auto;
    width: 90% !important;
  }
}

.p-multiselect-panel.p-component.p-connected-overlay-enter-done{
  z-index: 10000 !important;
}