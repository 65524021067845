.nav-link {
  color: var(--light-color) !important;
}

.mdm-logo {
  height: 4rem;
  /* background-image: url('../img/mdm-logo.svg'); */
  width: 100%;
  /* transition: background 0.75s ease-in-out; */
  padding: 0.5rem 0;
}

.dropdown :hover {
  cursor: pointer;
}

[class*=sidebar-dark-] {
  background-color: var(--dark-color);
}

.nav-link.active {
  background-color: var(--gray-bg-hover-menu-item) !important;
}

[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active,
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:focus,
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:hover {
  color: var(--light-color);
}

.sidebar-mini.vsc-initialized.sidebar-collapse div.mdm-logo {
  background-image: url('../img/icon-mdm-bg-black.svg');
  transition: background 0.75s ease-in-out;
}

.sidebar-mini.vsc-initialized.sidebar-collapse aside:hover div.mdm-logo {
  background-image: url('../img/mdm-logo.svg');
  transition: background 0.75s ease-in-out;
}

.version {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--light-color);
  text-align: center;
  height: 2rem;
  line-height: 2rem;
}

.sidebar{
  margin-bottom: 1.5rem;
  /* border: 1px solid blue; */
  height: calc(85% - (3.5rem + 1px));
}

.nav-item .subMenu{
  /* border: solid 1px red; */
  display: block !important;
  transition: all 1s ease-in-out; 
}

.containerAside{
  /* border: solid 1px red; */
  position: fixed !important;
  height: 100vh !important;
  min-height: auto !important;
}

@media (max-width: 992px) {

  .main-sidebar,
  .main-sidebar::before {
    box-shadow: none !important;
    margin-left: -15.625rem;
  }

  .sidebar-open .main-sidebar,
  .sidebar-open .main-sidebar::before {
    margin-left: 0;
  }
}