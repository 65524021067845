.parametersContainer{
    display: none;
    flex-direction: column;
}

.justified{
    text-align: justify;
}

.mainText{
    color: var(--gray-color);
    padding-top: 1rem;
}

.inputContainer{
    border-bottom: 1px solid var(--dark-color);
    padding: 1rem 0;
}

.inputRS{
    width: 4rem;
    text-align: center;
    font-size: 1rem;
}

.labelRS{
    font-size: 1.1rem;
}