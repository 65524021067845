.p-button {
  background: var(--dark-color) !important;
  border: var(--dark-color) !important;
}

.bodyDash {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 30rem;
  width: 100%;
}

.navLinkLastLocation{
  width: 100%;
  margin: 0.5rem 0;
}

.principalSection{
  margin-top: 2rem;
}

.findDevice{
  display: flex;
  align-items: center;
  position: absolute;
  top: -2rem;
  left: 0;
  /* border: 1px solid red; */
}

.findDevice p{
  margin-bottom: 0;
}

.findDevice i{
  background-color: rgba(59, 174, 53, 0.9);
  border-radius: 50%;
}

.findDevice .now{
  background-color: rgba(59, 174, 53, 0.3);
  color: rgba(59, 174, 53, 0.9);
  font-weight: 700;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.2rem;
}

@media (min-width: 480px) {
  .navLinkLastLocation{
    margin: 0;
    width: auto;
    position: absolute;
    top: -3.5rem;
    right: 0.5rem;
  }

  .findDevice{
    top: -3.5rem;
  }
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {

  .bodyDash {
    display: block;
    margin-top: 3rem;
    margin: auto;
    width: 100%;
    height: auto;
  }

  .bodyDashMap {
    position: relative;
    bottom: 29rem;
  }
}

/* version Desktop 992px hasta 1311px */
@media (min-width: 992px) {

  .bodyDash {
    margin-top: 0;
  }
}