.contenButtons {
    display: flex;
    align-items: center;
    margin: 2rem 0 2rem 0;
}

.contenButtons Button {
    background-color: var(--dark-color) !important;
    border: none;
    margin-right: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 0.5rem;
}

.contenButtons Button:hover {
    background-color: var(--gray-color) !important;
    border: 1px solid var(--dark-color);
}

.contenButtons Button.disabled {
    background-color: var(--gray-color) !important;
    cursor: inherit !important;
}

.contenButtons Button.disabled:hover {
    border: none;
}


.contenButtons Button i {
    margin-right: 0.5rem;
}