.headerCommand {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 0;
}

.infoTime {
    display: flex;
    gap: 0.4rem;
    padding: 0.5rem 0;
    align-items: baseline;
}

.connectCommand {
    font-size: 0.95rem;
}

.findDevice i {
    background-color: var(--green-light-color);
    border-radius: 50%;
}

.commandConnected {
    background-color: var(--green-color);
    color: var(--green-light-color);
    font-weight: 700;
    padding: 0 0.5rem;
    border-radius: 0.2rem;
}

.commandDisconnected {
    background-color: var(--gray-bg-color);
    color: var(--gray-paginator-dataTable-color);
    font-weight: 700;
    padding: 0 0.5rem;
    border-radius: 0.2rem;
}

.containerCommand {
    margin-bottom: 1rem;
}

.containerInputCommand {
    display: flex;
    gap: 0.7rem;
    padding: 0.5rem 0 1rem;
}

.inputCommand {
    padding: 0.5rem 1.2rem;
    width: 100%;
}

.btnCommand {
    padding: 0 0.5rem;
}

.btnHistory {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
}

.btnViewHistory {
    border: none;
    background-color: transparent;
}

.commandResponse {
    height: 16rem;
    resize: none;
    background-color: var(--dark-color);
    color: var(--light-color);
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1.5rem;
    overflow-y: scroll;
}

.commandResponse::-webkit-scrollbar {
    display: none;
}

.containerHistory {
    padding: 0 0.5rem !important;
    width: 110% !important;
    justify-content: flex-start !important;
    position: relative;
    left: -1rem;
    padding: 0;
    overflow-y: auto;
    box-shadow: none !important;
}

.commandHistoryExpanded {
    height: 10rem;
    resize: none;
    background-color: var(--light-color);
    border: none !important;
    font-weight: 500;
    padding: 0 0.5rem;
    box-shadow: none !important;
}

.commandHistory {
    height: 6rem;
    resize: none;
    background-color: var(--light-color);
    border: none !important;
    font-weight: 500;
    padding: 0 0.5rem;
    overflow: hidden;
    box-shadow: none !important;
}

.commandHistoryEmpty {
    height: 0;
    resize: none;
    border: none !important;
    padding: 0 0.5rem;
    overflow: hidden;
}

.containerHistoryBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* padding: 0.5rem 0.7rem; */
    padding: 0.5rem 0rem;
    gap: 0.5rem;
}

@media (min-width: 689px) {

    .infoTime {
        padding: 0.5rem 0px 1rem;
    }

    .containerCommand {
        margin-bottom: 1.5rem;
    }

    .btnCommand {
        padding: 0 1rem;
    }

    .containerHistory {
        width: 100% !important;
    }
}