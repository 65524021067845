.main-footer {
    height: 2rem !important;
    background-color: var(--light-color);
    color: var(--footer-color);
    text-align: center;
    padding: 0.0625rem;
    border-top: none !important;
    position: fixed;
    bottom: 0;
    /* border: 1px solid red; */
    width: 100vw;
    z-index: 1000;
}

.rights {
    font-size: 0.75rem;
}

@media (min-width: 768px) {
    body:not(.sidebar-collapse) .main-footer{
        margin-left: 9vw !important;
    }
}