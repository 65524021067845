.text-left {

}

.content-modal {
    display: flex;
    flex-direction: column;
    /* background-color: var(--dark-color); */
    background-image: url("../../img/bakground.mdm.jpg");
    background-repeat: no-repeat;
    border-radius: 0.6rem;
    margin: 0;
    color: var(--light-color);
    width: 100%;
    align-items: center;
    height: 62vh;
    align-items: center;
    max-height: 24rem;
    overflow-y: scroll;
    margin-right: 1rem;
}

.content-modal::-webkit-scrollbar {
    display: none;
}

.title-login {
    display: flex;
    margin: 2rem 0;
    justify-content: center;
    align-items: center;
}

.conteiner-login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.conteiner-login div {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0 .5rem;

}

.container-icon-input {
    position: relative;
    margin-top: 1rem !important;
}

.userIcon,
.passIcon {
    position: absolute;
    top: 0.8rem;
    left: 0.7rem;
}


.conteiner-login label {
    margin: 0.9rem .5rem 0rem 0.1rem !important;
    font-weight: 400 !important;
    font-size: small;
    width: 100%;
    text-align: left !important;
    padding: 0 0.5rem;
}

.conteiner-login input {
    color: var(--light-color);
    background-color: transparent;
    border: 0.063rem solid var(--gray-color) !important;
    border-radius: 0.3rem;
    margin-left: 0;
    padding: 0.4rem 3rem;
    width: 100%;
}

.content-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
}

.button-login {
    width: 85%;
    margin-top: 2rem;
}

::-webkit-input-placeholder {
    /* Google Chrome y Safari */
    color: var(--light-color);
}

.user-label {
    position: relative;
    left: 0;
    background: url("../../img/pass_icon.svg") center / contain no-repeat;
}

.buttonShow {
    background-color: transparent;
    color: var(--light-color);
    border-radius: 20%;
    padding: 0.7rem 0.625rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    border: none;
    position: absolute;
    cursor: pointer;
    left: 80%;
}

.buttonShow i {
    color: rgb(97, 193, 237);
}

@media (min-width: 689px) {

    .conteiner-login input {
        padding: 0.4rem 10.6rem 0.4rem 2rem;

    }

    .conteiner-login {
        width: 85%;
    }

    .content-modal {
        background-image: url("../../img/bakground.mdm.jpg");
        background-size: cover;
        color: var(--light-color);
        width: 100%;
        overflow-y: scroll;
        height: 60vh;
        align-items: center;
    }

    .buttonShow {
        left: 87% !important;
    }

}

@media (min-width: 992px) {

    .conteiner-login input {
        padding: 0.5rem 10.6rem 0.5rem 2rem;
    }

}