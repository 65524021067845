.actionsContainer{
    display: none;
    flex-direction: column;
}

.buttonsContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    width: 100%;
}

.buttonsContainer .buttonsRS{
    width: 100%;
    height: 3rem;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 0;
    background-color: var(--dark-bg-color) !important;
    margin-bottom: 0.5rem;
}

.buttonsContainer .buttonsRS:hover{
    background-color: var(--dark-color) !important;
}

.buttonsContainer .buttonsRS:active{
    background-color: var(--blue-button-color) !important;
    border: none !important;
    box-shadow: none !important;
}

.title{
    margin-top: 2rem;
    border-bottom: 1px solid var(--dark-color);
    font-weight: 500;
    font-size: 1.1rem;
    padding-bottom: 0.5rem;
}

.deviceStatusOptionsContainer{
    margin-top: 1rem;
}

.deviceStatusOptionsContainer .inputContainer{
    padding: 0.5rem;
}

.deviceStatusOptionsContainer .labelDeviceStatusOptions{
    margin-left: 0.5rem;
    margin-bottom: 0;
    font-weight: 500 !important;
}

.statusChangeInformationContainer{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.statusChangeInformationContainer label{
    font-weight: 500 !important;
}

.statusChangeInformationContainer .statusChangeInformation{
    resize: none;
    overflow-y: scroll;
    height: 8rem;
}

.error{
    border: 1px solid var(--red-color);
}

@media (min-width: 418px) {
    .buttonsContainer .buttonsRS{
        width: 46%;
    }
}

@media (min-width: 992px) {
    .buttonsContainer .buttonsRS{
        width: 11rem;
    }
}