.inputContainer{
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
}

.inputContainer input {
    height: 2.5rem;
    padding: 0 0.5rem;
}

.stateAndSelectContainer{
    display: flex;
    margin: 1rem 0;
    flex-wrap: wrap;
    align-items: center;
}

.selectRS{
    width: 8rem;
    margin-right: 3rem;
    background-color: var(--dark-bg-color);
    color: var(--light-color);
    height: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    line-height: 2rem;
}

.stateContainer{
    display: flex;
    align-items: center;
}

.stateContainer .labelState{
    margin: 0;
    padding-right: 1rem;
}

.inputContainer textarea {
    resize: none;
    overflow-y: scroll;
    height: 8rem;
}

/*StateRS*/

.state{
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.state p {
    margin: 0;
}

.stateCircle{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}

@media (min-width: 420px) {
    .selectRS{
        margin-bottom: 0;
    }
  }