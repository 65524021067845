.modalRolesContainer{
    display: flex;
    flex-direction: column;
}

.functionalitiesContainer{
    width: 100%;
    margin-top: 1rem;
    /* border: 1px solid black; */
}

.inputContainer{
    display: flex;
    flex-direction: column;
}

.inputContainer input {
    height: 2.5rem;
    padding: 0 0.5rem;
}

.selectAllContainer {
    /* border: 1px solid red; */
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.selectAllContainer label {
    margin: 0;
    font-weight: 300 !important;
    font-size: 0.9rem;
}

@media (min-width: 418px) {
    .buttonsContainer .buttonsRS{
        width: 46%;
    }
}

@media (min-width: 992px) {
    .modalRolesContainer{
        flex-direction: row;
        justify-content: space-between;
    }

    .functionalitiesContainer{
        width: 70%;
        margin-top: 0;
    }

    .inputContainer{
        padding: 0 0.5rem;
    }
}