.containerMap{
    height: 26rem;
}

.containerInformationFence{
    margin: 1.5rem 0 1rem 0;
}

.bold{
    font-weight: bold;
    margin-right: 0.5rem;
}

.containerFlex{
    display: flex;
}

.containerFlexCoordinates{
    display: flex;
    flex-direction: column;
}

p, li{
    margin: 0 0 0.5rem 0;
}

.containerFlexDate{
    display: flex;
    flex-direction: column;
}

@media (min-width: 450px) {
    .containerFlexDate{
        flex-direction: row;
    }
  }