.containerNotFound {
    text-align: center;
    margin-top: 30dvh;
    opacity: 1;
    transition: opacity 0.10s ease
}


.transitionNotFound {
    text-align: center;
    margin-top: 30dvh;
    opacity: 0;
    transition: opacity 0.10s ease
}

.titleNotFound {
    color: var(--light-color)
}

.textNotFound {
    color: var(--light-color)
}