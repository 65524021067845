.flexContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-color);
    border-radius: 0.625rem;
    box-shadow: 0 0.25rem 0.625rem 0 var(--light-shadow-color);
    margin: 0.5rem 0;
    height: 15rem;
}
.cardsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.infoCard{
    width: 100% !important;
}

.containerBoxChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 20rem !important;
}

.containerBoxChart  .sliderLabel {
    font-size: large !important;
    font-weight: 600 !important;
    margin: 0 0.313rem 0.313rem 0 !important;
    padding-top: 0.4rem;
}

/* Bateria*/
.batteryContainer{
    position: absolute;
}

.batteryContainer .battery{
    box-sizing: content-box;
    width: 10rem;
    height: 8rem;
    /* border: 0.4rem solid var(--blue-battery-color); */
    border-radius: 0.5rem;
    position: relative;
}


.batteryContainer .battery:before{
    position: absolute;
    content: '';
    height: 4rem;
    width: 1rem;
    top: 2rem;
    right:  -1rem;
    border-radius: 0 0.5rem 0.5rem 0;
}


.batteryContainer .battery .charge{
    width: 100%;
    height: 100%;
    border: 0.5rem solid var(--light-color);
    border-radius: 0.5rem;
}
.batteryContainer .battery.darkBlue{
    border: 0.4rem solid var(--dark-blue-battery-color);
}
.batteryContainer .battery.darkBlue:before{
    background-color: var(--dark-blue-battery-color);
}
.batteryContainer .battery.darkBlue .charge{
    background-color: var(--dark-blue-battery-color);
}

.batteryContainer .battery.blue{
    border: 0.4rem solid var(--blue-battery-color);
}
.batteryContainer .battery.blue:before{
    background-color: var(--blue-battery-color);
}
.batteryContainer .battery.blue .charge{
    background-color: var(--blue-battery-color);
}

.batteryContainer .battery.red{
    border: 0.4rem solid var(--red-battery-color);
}
.batteryContainer .battery.red:before{
    background-color: var(--red-battery-color);
}
.batteryContainer .battery.red .charge{
    background-color: var(--red-battery-color);
}

.batteryContainer .chargeLevel{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.batteryContainer .chargeLevel p{
    font-size: 2rem;
    font-weight: 500;
    color: var(--dark-color);
    margin-bottom: 0;
}

.batteryContainer .chargeLevel i{
    font-size: 2rem;
    display: none;
    margin-right: 0.5rem;
}

.batteryContainer .chargeLevel i.plug{
    color: var(--red-battery-color);
}

.batteryContainer .chargeLevel i.bolt.darkBlue{
    color: var(--dark-blue-battery-color);
}
.batteryContainer .chargeLevel i.bolt.blue{
    color: var(--blue-battery-color);
}
.batteryContainer .chargeLevel i.bolt.red{
    color: var(--red-battery-color);
}
.batteryContainer .battery .charge.active .chargeLevel i{
    display: block;
}

.active{
    animation: charge-animation 3s infinite linear;
}

.cardsContainer .boxChart{
    width: 100%;
    min-width: 13rem;
    padding: 0 !important;
}

@keyframes charge-animation{
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
}

 
@media (min-width: 768px) {
    .infoCard{
        width: 47% !important;
    }
    .batteryContainer .battery{
        width: 16rem;
    }

    .cardsContainer .boxChart{
        width: 32rem;
        padding: 0 !important;
        margin: 1rem;
    }
}