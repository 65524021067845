.datatable-doc-demo .p-paginator .p-paginator-current {
    margin-left: 0;
}

.datatable-doc-demo .p-progressbar {
    height: 0.5rem;
    background-color: var(--light-shadow-color);
}

.datatable-doc-demo .p-progressbar .p-progressbar-value {
    background-color: var(--cyan-shadow-color);
}

.datatable-doc-demo .p-datepicker {
    min-width: 25rem;
}

.datatable-doc-demo .p-datepicker td {
    font-weight: 400;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem 0.5rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-thead>tr>th {
    text-align: left;
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
    padding: 1.25rem 0.9rem !important;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.p-datatable .p-column-header-content {
    justify-content: center;
}

.headerTable {
    display: flex;
    justify-content: space-between;
}

.headerTableColumn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.headerTableColumn h3 {
    margin-bottom: 1rem;
}

.actionDash {
    text-decoration: none;
}

#tableApps {
    min-height: 35rem;
}

th.selectionChexbox .p-column-header-content {
    display: none !important;
}

.p-inputtext {
    width: 100%;
    border: none !important;
    border-bottom: 0.0625rem solid var(--dark-color) !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0.4375rem 0.4375rem 0.4375rem 2.8125rem !important;
    height: 2.5rem !important;
}

.containerHeader {
    width: 100% !important;
    display: flex;
    flex-direction: column;
}

.containerHeader .buttonExtract {
    flex-direction: row;
}

.containerHeader .buttonExtract button {
    margin-bottom: 0.2rem;
}

.containerHeader .buttonExtract button:hover {
    background-color: var(--gray-color) !important;
}

.p-input-icon-left {
    width: 100%;
    background-color: var(--light-color);
    margin-top: 0 !important;
}

.p-input {
    width: 95%;
    background-color: var(--light-color) !important;
    padding: 1rem !important;
}

.btn-white {
    background-color: var(--gray-light-color) !important;
    color: var(--dark-color) !important;
    border-color: transparent !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--gray-color) !important;
}

.p-paginator button {
    color: var(--gray-paginator-dataTable-color) !important;
    background: transparent !important;
}

.p-paginator button:hover {
    background: var(--gray-shadow-color) !important;
}

.p-button.p-button-icon-only.p-button-rounded {
    background-color: var(--gray-light-color) !important;
    color: var(--dark-color) !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only{
    box-shadow: none !important;
}

.p-button.p-button-icon-only.p-button-rounded:hover {
    background-color: var(--gray-color) !important;
}

button.p-button.p-component.p-speeddial-button.p-button-rounded.p-speeddial-rotate.p-button-danger.p-button-icon-only {
    width: 3rem;
    height: 3rem;
}

.p-dropdown-panel {
    z-index: 1100 !important;
}

.p-input-icon-left,
.p-input-icon-right {
    position: relative;
    display: inline-block;
    margin-top: 0.6rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator .p-paginator-pages {
    padding: 0.1rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: var(--dark-color);
    background-color: transparent;
    min-width: 2rem;
    height: 2rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 0.5rem;
}

.columns {
    height: 5.5rem;
}

.p-button.p-button-secondary {
    font-size: 0.875rem !important;
    height: 3rem !important;
}

.rowsDropdown {
    margin: 0 1.5rem 0 0.5rem;
    height: 2.5rem;
    width: 4rem;
}

.rowsDropdown .p-dropdown-trigger {
    width: 1.5rem !important;
}

.filterDropdown {
    width: 100%;
    height: 2.5rem;
    margin-top: 1rem;
}


.filterDropdown span.p-dropdown-label.p-inputtext,
.rowsDropdown span.p-dropdown-label.p-inputtext {
    padding: 0.4375rem !important;
    text-align: center !important;
    font-weight: bold;
    text-transform: capitalize !important;
}

span.p-input-icon-left .pi.pi-search.search {
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 0.3125rem;
    background-color: var(--dark-color) !important;
    color: var(--light-color);
    position: absolute;
    top: 0.5625rem;
    left: 0;
}

i.pi.pi-search.search{
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 0.3125rem;
    background-color: var(--dark-color) !important;
    color: var(--light-color);
}

.containerHeader .searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    flex-direction: column;
}
.search-container{
    display: flex;
    width: 100%;
}

.containerHeader .containerButtons {
    display: flex;
    justify-content: space-between;
}

.footerDataTable {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    text-align: center;
}

.buttonExtract .p-button.p-button-success:enabled:focus,
.p-button.p-component.mr-2.p-button-icon-only:enabled:focus,
.containerButtons .p-button.p-button-success:enabled:focus {
    box-shadow: none;
}

.containerButtons .p-button.p-button-success.mr-2.uncheck {
    margin-right: 0 !important;
}

.containerButtons .p-button.p-button-success.mr-2.uncheck:hover {
    background-color: var(--gray-shadow-color) !important;
}

.notVisible{
    display: none !important;
}

.visible{
    display: flex !important;
}

.label-radio-button{
    font-size: 1rem;
    margin: 0;
}

.category-selector-container{
    display: none !important;
    width: 100%;
}

.p-row-editor-init, .p-row-editor-save, .p-row-editor-cancel{
    color: #6c757d !important;
}

.p-tag{
    color: #495057 !important;
    background-color: transparent !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.p-button.p-component.p-splitbutton-defaultbutton{
    display: none !important;
}

.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only{
    height: 3rem;
    background-color: var(--gray-light-color) !important;
    border: none !important;
    color: var(--dark-color) !important;
    border-radius: 50% !important;
}

.categoriesToEditDropdown{
    width: 15rem !important;
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {

    /* .containerHeaderApss{
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    } */

    .datatable-doc-demo .p-paginator .p-paginator-current {
        margin-left: auto;
    }

    .datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-header {
        padding: 1rem;
    }

    .datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator {
        padding: 1rem;
    }

    .datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
    }

    button.p-button.p-component.p-speeddial-button.p-button-rounded.p-speeddial-rotate.p-button-danger.p-button-icon-only {
        width: 4rem;
        height: 4rem;
    }

    .containerHeader .searchContainer {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        gap: 1.5rem;
    }

    .filterDropdown {
        width: 14.375rem;
        margin-top: 0;
    }

    .search-container{
        width: 95%;
    }

    .category-selector-container{
        width: 14.375rem;
    }
}