:root {
    --dark-color: #000000;
    --dark-bg-color: #333333;
    --dark-light-color: #00000029;
    --dark-shadow-color: #000000bf;
    --secondary-color: #3F9BFF;
    --sky-blue-color: #00E0FF;
    --blue-light-color: #9fa8da;
    --blue-button-color: #0053F5;
    --blue-shadow-color: #212529;
    --blue-cyan-color: #4338CA;
    --dark-blue-battery-color: #003481;
    --blue-battery-color: #0044ef;
    --red-battery-color: #C64242;
    --tertiary-color: #003686;
    --quaternary-color: linear-gradient(180deg, rgba(0, 122, 255, 1) 0%, rgba(99, 219, 245, 1) 100%);
    --quinary-color: #badbff;
    --red-color: #ff0000;
    --light-color: #fff;
    --light-intermediate-color: #f8f9fa;
    --light-shadow-color: #dbe8f8;
    --gray-color: #989898;
    --gray-speedDial-color: #999999;
    --gray-bg-color: #c3c3c3;
    --gray-bg-hover-menu-item: #d9d9d939;
    --gray-intermediate-color: #6969697e;
    --gray-border-color: #a8a8a87e;
    --gray-paginator-dataTable-color: #3e3e3e;
    --gray-light-color: #E4E4E4;
    --gray-shadow-color: #11111150;
    --gray-light-btn-menu: #e4e4e4;
    --grayish-blue: #495057;
    --green-light-color: #3bae35e6;
    --green-color: #3cb0364d;
    --cyan-color: #80cbc4;
    --cyan-shadow-color: #607d8b;
    --yellow-color: #ffd54f;
    --footer-color: #858585;
}