.bodyHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 2rem;
}

.spaceCharts {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    height: auto;
}

.bodyHome .boxChart{
    min-width: 13rem;
    padding: 0 !important;
    margin: 2rem 0;
    position: relative;
}

.dateFilterContainer {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.dateFilterContainer .btnDateUseTimeApps{
    background-color: var(--gray-light-btn-menu);
    border-radius: 0;
    color: var(--dark-color);
    border: none;
    width: 4rem;
    font-size: 0.8rem;
    font-weight: 500 !important;
    height: 2rem;
    margin: 0 0.1rem !important;
    padding: 0 0.2rem ;
    line-height: 2rem;
}

.dateFilterContainer .btnDateUseTimeApps:hover/* ,  .dateFilterContainer .btnDateUseTimeApps:active */{
    background-color: var(--dark-color) !important; 
    color: var(--light-color) !important;
}

.boxChart{
    width: 100%;
}

.boxChart .filters{
    width: 100%;
    padding: 0 0.5rem;
    position: absolute;
    top: 5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.boxChart .dropdown {
    height: 2.2rem;
    width: 13rem;
}

.boxChart .categoriesContainer .floatLabel{
    color: var(--gray-color);
    margin: 0;
    font-weight: 500;
    font-size: 0.8rem;
    padding-left: 0.5rem;
}

.marginTop{
    margin-top: 5rem;
    height: 23rem;
}

@media (min-width: 768px) {
    .bodyHome {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .spaceCharts .boxChart{
        margin: 1rem;
        width: 100%;
        max-width: 20rem;
    }

    .spaceCharts {
        flex-direction: row;
    }

    .boxChart{
        width: 47%;
    }
}

@media (min-width: 1200px) {

    .spaceCharts .boxChart{
        max-width: 22rem;
    }

    .spaceCharts {
        flex-direction: row;
    }
}