.title {
    margin: 0;
    font-size: 1.5rem;
}

.containerCheckbox {
    margin-left: 0.5rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 2.8125rem;
    height: 1.5625rem;
    margin: 0 0.5rem 1rem 0;
}

.labelNotifications {
    font-weight: 500 !important;
    font-size: 0.9rem;
}

.subtitleNotif {
    margin-top: 2rem;
    margin-left: 0.5rem;
}

.selectNotif {
    width: 100%;
    border: 0.1rem solid var(--gray-border-color) !important;
    height: 2.875rem;
    border-radius: 0.3rem;
    padding-left: 0.5rem;
    margin-left: 0.1rem;
}

.selectNotif:focus {
    border: 0.1rem solid var(--gray-color) !important;
}

.selectDate {
    width: 100%;
    border: 0.1rem solid var(--gray-border-color) !important;
    height: 2.875rem;
    border-radius: 0.3rem;
    padding-left: 0.5rem;
    margin-bottom: 2rem;
}

.selectDate:focus {
    border: 0.1rem solid var(--gray-color) !important;
}

.subtitleAction {
    margin-top: 2.5rem;
    font-size: 1.1rem;
}

.custom_hr {
    margin: 0 0 2rem 0;
}

.subtitleConfig {
    margin-left: 0.5rem;
}

.container_custom_checkbox {
    margin-left: 0.5rem;
}

.customCheckbox {
    font-family: system-ui, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    /* line-height: 1.1; */
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5rem;
    border: none;
    margin-bottom: 0.9rem;
}

.checkboxText {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
}

.subtitleGeo {
    margin-top: 0.5rem;
    font-size: 1.125rem;
}

.container_btn {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.buttonSave {
    background-color: var(--dark-color);
}

.buttonSave:hover {
    background-color: var(--gray-color);
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {
    .container_btn {
        flex-direction: row;
        justify-content: flex-end;
    }
}