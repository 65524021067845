.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.0625rem solid var(--gray-color);
    margin-bottom: 1.5rem;
    padding: 0.5rem 0;
}

.title {
    font-size: 1.8rem;
}

.gestion {
    width: 100%;
    display: flex;
    align-items: flex-end;
}
.msgNoRole{
    margin: 0;
    font-weight: 700;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: rgb(228, 228, 228);
}

.exclamationIcon{
    margin-right: 0.5rem;
}

.HeaderDashboardModal{
    position: relative;
}

.RealTimeIconDashboardModal {
    display: flex;
    position: absolute;
    bottom: 0;
}

.RealTimeIconDashboardModal .inLine{
    margin: 0.25rem;
    color: rgba(59, 174, 53, 0.9);
}

.RealTimeIconDashboardModal .offLine{
    margin: 0.25rem;
    color: var(--gray-color);
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {

    .header {
        margin-bottom: 1rem;
        padding: 1rem 0;
    }

    .title {
        font-size: 2rem;
    }
}