.componentBox {
  border-bottom: solid 0.063rem var(--gray-color);
  padding: 1.875rem 1.25rem 1.875rem 0;
  justify-content: center;
  height: 5.5rem !important;
}

.containerDiv {
  width: 100%;
  margin-bottom: 1rem;
}

.containerDivMessage {
  flex-direction: column;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 1.563rem;
  margin-right: 0.3rem;
  margin-top: 0.3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-color);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 40%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.875rem;
  width: 0.875rem;
  left: 0.125rem;
  bottom: 0.313rem;
  background-color: var(--light-color);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--dark-color);
}

input:focus+.slider {
  box-shadow: 0 0 0.063rem var(--dark-color);
}

input:checked+.slider:before {
  -webkit-transform: translateX(1.625rem);
  -ms-transform: translateX(1.625rem);
  transform: translateX(1.625rem);
}

.sliderLabel {
  font-size: large !important;
  font-weight: 600 !important;
  margin: 0 0.313rem 0.313rem 0 !important;
  padding-top: 0.4rem;
}

.slider.round:before {
  border-radius: 50%;
}

.inputsLabels {
  margin-left: 0.438rem;
  border-color: var(--dark-light-color) !important;
}

.focus {
  display: block;
  outline: none;
  height: 2.5rem;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--grayish-blue);
  background-color: var(--light-color);
  background-clip: padding-box;
  border: 0.063rem solid var(--gray-intermediate-color) !important;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.focus:focus {
  box-shadow: 0 0 0.313rem 0.125rem var(--dark-color);
  -webkit-box-shadow: 0 0 0.313rem 0.125rem var(--dark-color);
  -moz-box-shadow: 0 0 0.313rem 0.125rem var(--dark-color);
}

.contentInputFile {
  display: none !important;
  height: 8.75rem;
  border: 0.063rem solid var(--light-shadow-color);
  border-radius: 1rem;
  background-color: var(--light-intermediate-color);
  padding: 1rem;
  margin: 1rem 0;
}

.contentInputFileGroups {
  display: flex !important;
  flex-direction: column !important;
}

.contentFileAndButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}

.file {
  width: 80%;
  padding: 0.313rem;
  background-color: var(--light-color);
}

.btn {
  justify-content: center;
  width: 8.125rem;
  margin: 0;
  box-shadow: none !important;
  margin-left: 0.625rem !important;
}

.subtitle {
  font-size: 1.5rem;
}

.margin{
  margin: 1rem 0;
}