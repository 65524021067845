.titleModal{
    font-size: 1.2rem;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.input{
    padding: 0.3rem;
    height: 2rem;
}

.code {
    display: none;
}

.labelCodeUnlock{
    font-weight: 700;
    font-size: 1rem;
}

.loadingContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.codeUnlock{
    padding: 0.3rem;
    height: 2rem;
    font-weight: 700;
    font-size: 1rem;
    margin: 0 0 1rem 0;
    background-color: var(--gray-light-color);
    color: var(--tertiary-color);
}

.errorMessage {
    color: var(--red-color);
    font-size: 0.75rem;
    margin: 0.5rem 0 0;
}