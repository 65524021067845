.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-left {
    left: 0;
    bottom: 0;
}

.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-right {
    right: 0;
    bottom: 0;
}

.p-button.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 0.13rem var(--light-color), 0 0 0 0.3rem rgb(31, 31, 31), 0 0.06rem 0.13rem 0 var(--dark-color) !important;
}

.btnDial1,
.btnDial2,
.btnDial3,
.btnDial4,
.btnDial5 {
    width: 3rem !important;
    height: 3rem !important;
    transform: scale(0) !important;
    opacity: 0;
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity .8s !important;
    will-change: transform;
}

/*//! ESTA RARO//*/
/* .btnDial2 {
    transition-delay: 0ms;
}

.btnDial2 {
    transition-delay: 30ms;
}

.btnDial3 {
    transition-delay: 60ms;
} */

.menuSpeedDial {
    position: fixed;
    top: 11rem;
    right: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 15.625rem;
    height: 19rem;
}

.menuZIndex{
    z-index: 2 !important;
}

.btnMenuSpeedDial {
    position: fixed !important;
    top: 6rem;
    right: 1.125rem;
    z-index: 2 !important;
    border-radius: 50% !important;
    background-color: var(--gray-light-color) !important;
    color: var(--dark-color) !important;
    border-color: transparent !important;
    width: 4rem !important;
    height: 4rem !important;
    justify-content: center;
}

.btnMenuSpeedDial:hover {
    background-color: var(--gray-color) !important;
}

.btnApps, .btnRxart {
    border-radius: 50% !important;
    background-color: var(--gray-light-color) !important;
    color: var(--dark-color) !important;
    border-color: transparent !important;
    width: 3rem !important;
    height: 3rem !important;
    transform: scale(0);
    opacity: 0;
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity .8s !important;
    will-change: transform;
}

.btnApps:hover, .btnRxart:hover {
    background-color: var(--gray-speedDial-color) !important;
}

.btnIRight {
    transform: rotate(45deg);
    transition: all .3s ease-in-out;
}

.btnILeft {
    transform: rotate(0deg);
    transition: all .3s ease-in-out;
}

.openBtn {
    transform: scale(1) !important;
    opacity: 1 !important;
}