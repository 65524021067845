.spacePie {
    display: flex;
    justify-content: center;
    width: 16rem !important;
}

.messageError {
    text-align: center;
    line-height: 2;
}

@media (min-width: 689px) {
    .spacePie {
        width: 16.66rem !important;
    }
}