.tableHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.tableHead .listButton {
    background-color: var(--dark-color);
}

.tableHead .listButton:hover {
    background-color: var(--gray-color);
}
