.inputsTreeContainer{
    /* border: 1px solid red; */
    width: 100%;
    padding: 0.5rem;
    height: 24rem;
    overflow-y: scroll;
    overflow-x: hidden
}

.inputContainer{
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.checkBox{
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
}

.checkBoxSelectAll{
    width: 1.2rem;
    height: 1rem;
    margin-right: 0.8rem;
}

.checkBoxSelectAll label{
   margin: 0;
}

.labelCheckBox{
    font-weight: 500 !important;
    font-size: 1rem;
    margin-bottom: 0 !important;
}

.divButton{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 2rem;
    align-items: flex-start;
    max-width: 450px;
}

.containerMultiSelect{
    margin-top: 1rem;
}

.button{
    width: 75px;
}

.title{
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1.1rem;
    text-decoration: underline;
}

.containerTitleSection {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.containerInputsSection{
    margin-bottom: 2rem;
    /* border: 1px solid red; */
}

