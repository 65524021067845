.messagesContainer{
    display: none;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
}

.spanMessage{
    margin-left: 0.5rem;
}

.textareaRS{
    resize: none;
    overflow-y: scroll;
    height: 8rem;
}