canvas {
    width: 100% !important;
    margin: 0 auto;
}

@media (min-width: 992px) {
    canvas {
        width: 95% !important;
    }
}

@media (min-width: 1311px) {}